<template>


         <div class="flex mycard userNotLogin justify-center items-center">
        <vx-card>
          <div slot="no-body">
            <div
              class="no-gutter justify-center items-center"
              style="background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
                box-shadow: -3px 0px 22px #00000029;
                border-radius:31px 31px 31px 31px;
             opacity: 1;
            "
            >
              <div class="align-center p-10">
                <img
                  src="@/assets/images/footer_logo.png"
                  style="max-width:150px ;"
                  alt="login"
                  class="mx-auto mt-3"
                />
                <div class="mt-10">
                  <p
                    style="text-align: initial;
                  font: normal normal 200 20px/21px Futura PT;
                       letter-spacing: 0px;
                        color: #FFFFFF;"
                  >
                    <!-- in order to -->
                   {{$t('For')}}  {{$t(this.MessageAction)}}, {{$t('PleaseLoginOrRegisterFirst')}}
                      <!-- please click here to sign in or register" -->

                  </p>
                </div>


                <div
                  class="flex flex-wrap justify-center flex-col-reverse sm:flex-row"
                >
                  <vs-button
                    class="w-full sm:w-auto mt-2 text-center text-primary"
                    color="white"
                    @click="GotoLogin"
                    >{{ $t("Here") }}</vs-button
                  >
                </div>

              </div>
            </div>
          </div>
        </vx-card>
      </div>


</template>
<script>

export default {
  data() {
    return {

    };
  },
   props:{
        MessageAction:{
            type:Object,
            required:true
        },

    },
  methods: {
    GotoLogin(){
this.$router.push({ name: "page-login" });
    }
  },
created() {

  },
};
</script>
<style>
#userNotLogin table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 22px#00000029;

}


#userNotLogin .background {
  color: rgb(255, 9, 9);
}


</style>
