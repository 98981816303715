<template>
  <div id="packageDetail">
 
    <!-- <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("Package") }}
      </p>
    </div> -->

    <div class="container2">
      <h3 class="darkBlue mt-10">{{ medicalPackage.Name }}</h3>

      <p class="mt-5" v-html="medicalPackage.Description"></p>

      <p class="mt-5">{{$t("DurationByDay")}} : {{medicalPackage.DurationByDay}}</p>

      <h5 class="mt-5" v-if=" medicalPackage.MedicalPackageIncludedItems!=null && medicalPackage.MedicalPackageIncludedItems!=undefined&& medicalPackage.MedicalPackageIncludedItems.length>0">{{ $t("ThisPackageincludes") }}</h5>
      <point
        class="ml-5 mr-5"
        v-for="item in medicalPackage.MedicalPackageIncludedItems"
        :data="item"
        :key="item.title"
      />
      <h5 v-if=" medicalPackage.MedicalPackageExcludedItems!=null && medicalPackage.MedicalPackageExcludedItems!=undefined&& medicalPackage.MedicalPackageExcludedItems.length>0" class="mt-5">{{ $t("ThisPackagedoesnotinclude") }}</h5>
      <point
        class="ml-5 mr-5"
        v-for="item in medicalPackage.MedicalPackageExcludedItems"
        :data="item"
        :key="item.title"
      />
    </div>
    <div vx-col class="w-full" style="text-align: end">
      <div class="vx-col">
        <div>
          <vs-button
            color="rgb(255, 255, 255)"
            class="text-2xl lg:w-1/4 shadow"
            text-color="rgb(0, 194, 38)"
          >
            <p
              :style="[
                medicalPackage.Discount > 0 ||
                medicalPackage.PercentageDiscount > 0
                  ? { 'text-decoration': 'line-through' }
                  : {},
              ]"
            >
              {{ $t("Price") }}: {{ medicalPackage.PriceInUSD }} $
            </p>
          </vs-button>
        </div>

        <div class="mt-5">
          <vs-button
            v-if="
              medicalPackage.Discount > 0 ||
              medicalPackage.PercentageDiscount > 0
            "
            color="rgb(255, 255, 255)"
         class="text-2xl lg:w-1/4 shadow"
            text-color="rgb(0, 194, 38)"
          >
            <p
              v-if="
                medicalPackage.Discount > 0 ||
                medicalPackage.PercentageDiscount > 0
              "
            >
              {{ $t("Price") }}: {{ medicalPackage.PriceAfterDiscount }} $
            </p>
          </vs-button>
        </div>
      </div>
      <div>
        <vs-button
          class="text-2xl mt-5 lg:w-1/4"
          text-color="rgb(255, 255, 255)"
          @click="
            $store.state.auth.isUserLoggedIn()
              ? (ShowConfirmation = true)
              : (ShowLoginPopup = true)
          "
        >
          {{ $t("ApplyforPackage") }}
        </vs-button>
      </div>
    </div>

    <!-- first popup -->
    <vs-popup
      fullscreen
      class="popcard"
      title=""
      :active.sync="ShowConfirmation"
    >
      <div
        class="mt-10 flex w-full bg-img items-center justify-center"
        id="page-login"
      >
        <div class="m-4 vx-col mycard" style="width: 70%">
          <vx-card>
            <div class="absolute flex inset-x-0 top-0 mt-6 justify-center">
              <img
                src="@/assets/images/logo.png"
                alt=""
                style="max-width: 150px"
              />
            </div>

            <div slot="no-body">
              <div
                class="
                  flex
                  popBackGround
                  vx-row
                  no-gutter
                  justify-center
                  items-center
                "
              >
                <div
                  class="
                    pl-10
                    pr-10
                    vx-col
                    hidden
                    sm:hidden
                    md:hidden
                    lg:block lg:w-1/2
                    self-center
                  "
                >
                  <p
                    style="
                      padding: 0px;
                      font: normal normal 50 20px/30px UbuntuRegular;
                      color: #ffffff;
                      opacity: 1;
                    "
                  >
                    {{ $t("OneSetPackage") }}
                    <br />
                  </p>
                  <div class="mr-2 ml-2">
                    <div class="vx-row pt-3">
                      <div class="vx-col pt-2">
                        <img
                          src="@/assets/images/logo/appointment.png"
                          style="max-width: 50px"
                          alt=""
                        />
                      </div>
                      <div class="vx-col">
                        <p class="OtherPageStatment pt-3 whitecolor">
                          {{ $t("OneSetPackage") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- second side -->
                <div
                  class="vx-col sm:w-1/2 md:w-full lg:w-1/2 d-theme-dark-bg"
                  style="border-radius: 31px 31px 31px 31px"
                >
                  <div class="p-8 mt-10">
                    <div class="mb-4">
                      <h6
                        class="bg-white mt-3 text-black"
                        style="margin-left: 10px"
                      >
                        {{ $t("From") }}:
                        <span
                          style="color: #aba9a4"
                          v-if="Model.DateFromFormated"
                        >
                          {{ Model.DateFromFormated }}</span
                        >
                        <span style="color: #aba9a4" v-else>
                          {{ $t("ChoiseDate") }}</span
                        >
                        {{ $t("To") }}:
                        <span
                          style="color: #aba9a4"
                          v-if="Model.DateToFormated"
                        >
                          {{ Model.DateToFormated }}</span
                        >
                        <span style="color: #aba9a4" v-else>
                          {{ $t("ChoiseDate") }}</span
                        >
                      </h6>
                    </div>
                    <div class="vx-row mt-2">
                      <p class="vx-col lg:w-1/3">{{ $t("PreferredDate") }}</p>
                      <div class="pl-12 pr-12 mt-3">
                        <v-date-picker
                          v-model="Model.range"
                          multiple="true"
                          is-range
                          :locale="$vs.rtl ? 'ar' : 'en'"
                          @change="changeDateFormat"
                          :min-date="addDays(4)"
                          @dayclick="changeDateFormat"
                        />
                      </div>
                    </div>

                    <div class="vx-row mt-5">
                      <p class="vx-col lg:w-1/3">
                        {{ $t("HowManyPassengers") }}
                      </p>
                      <vs-input
                        class="vx-col lg:w-2/3 w-full"
                        v-model="Model.NumberOfCompanions"
                      />

                      <p
                        class="text-align:center"
                        style="color: rgb(255, 130, 0)"
                      >
                        *{{ $t("PassengerNote") }}
                      </p>
                    </div>

                    <div class="vx-row mt-2">
                      <p class="vx-col lg:w-1/3 w-full">
                        {{ $t("NumberOfBeneficiary") }}
                      </p>
                      <vs-input
                        class="vx-col lg:w-2/3 w-full"
                        v-model="Model.NumberOfBeneficiary"
                      />
                    </div>

                    <div
                      class="
                        mt-5
                        flex flex-wrap
                        justify-center
                        flex-col-reverse
                        sm:flex-row
                      "
                    >
                      <vs-button
                        class="w-full sm:w-auto text-center"
                        color="#004477"
                        @click="ReseredMedicalPackage"
                        :disabled="
                          !(
                            Model.range &&
                            Model.NumberOfBeneficiary &&
                            Model.NumberOfCompanions
                          )
                        "
                        >{{ $t("Confirm") }}</vs-button
                      >
                    </div>

                    <div class="text-center">
                      <vs-button
                        type="line"
                        color="black"
                        @click="ShowConfirmation = false"
                        class="w-full sm:w-auto mb-8 sm:mb-auto mt-2 sm:mt-auto"
                        >{{ $t("Back") }}</vs-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowLoginPopup">
      <UserNotLogin
        :MessageAction="'BookAnMedicalPackage'"
        v-if="ShowLoginPopup"
        @closePop="ShowLoginPopup = false"
      ></UserNotLogin>
    </vs-popup>
  </div>
</template>
<script>
import point from "../../components/point.vue";
import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import moduleReservedPackage from "@/store/reservedPackage/moduleReservedPackage.js";
import UserNotLogin from "@/views/UserNotLogin.vue";
import moment from "moment";

export default {
  components: {
    point,
    UserNotLogin,
  },
  data() {
    return {
      EnglishLan: false,
      ShowConfirmation: false,
      Model: {},
      medicalPackage: {},
      ShowLoginPopup: false,
      value1: "",
    };
  },
  props:{
     PackageID:Number
  },
  watch:{
    PackageID()
    {
        var id = this.PackageID;
        if (id) {
          this.$store
            .dispatch("MedicalPackageList/GetMedicalPackage", id)
            .then((res) => {
              this.medicalPackage = res.data.Data;
            });
        }
    }
  },
  methods: {
    changeDateFormat() {
      debugger;
      this.Model.DateFromFormated = moment(this.Model.range.start).format("LL");
      this.Model.DateToFormated = moment(this.Model.range.end).format("LL");
    },
    ConfirmPayment() {},
    addDays(days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    back() {
      this.$router.go(-1);
    },
    ReseredMedicalPackage() {
      debugger;
      var model = {};
      model.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      model.MedicalPackageID = this.medicalPackage.ID;
      model.StatusID = 1;
      model.NumberOfCompanions = this.Model.NumberOfCompanions;
      model.NumberOfBeneficiary = this.Model.NumberOfBeneficiary;
      model.PreferrdDateFrom = this.Model.range.start;
      model.PreferrdDateTo = this.Model.range.end;
      model.MarkupFees = this.medicalPackage.MarkupFees;
      model.VAT = this.medicalPackage.VAT;
      model.PriceAfterDiscount = this.medicalPackage.PriceAfterDiscount;
      model.DurationByDay = this.medicalPackage.DurationByDay;
      this.$store
        .dispatch("ReservatedPackage/AddReservedMedicalPackage", model)
        .then((res) => {
          this.ShowConfirmation = false;

          this.$vs.notify({
            title: this.$t("Successfully"),
            text: this.$t("youhavesuccessfullyreservethispackage"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        });
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }
    if (!moduleReservedPackage.isRegistered) {
      this.$store.registerModule("ReservatedPackage", moduleReservedPackage);
      moduleReservedPackage.isRegistered = true;
    }

    debugger;
    var id =   this.$route.params.PackageID;
    if (id) {
      this.$store
        .dispatch("MedicalPackageList/GetMedicalPackage", id)
        .then((res) => {
          this.medicalPackage = res.data.Data;
        });
    }
  },
};
</script>
<style >
.mycard .vx-card {
  border-radius: 31px;
}

.customInput {
  border-radius: 10px;
  padding: 5px;
  height: 40px;
}
.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.popBackGround {
  background: 
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
.popcard .vx-card {
  box-shadow: unset;
}
</style>